

















































import Vue from "vue";
import userManager from "@/services/userManager";
import StatisticsPage from "@/components/StatisticsPage.vue";
import coreApiClient from "@/services/apis/coreApiClient";
import { displayItemsWithSku } from "@/utils/marketItemDisplay";
import makeSelectOptions from "@common/util/makeSelectOptions";
import purchaseCommandDeliveryStatuses from "@/constants/purchaseCommandDeliveryStatuses";
import purchaseCommandPaymentStatuses from "@/constants/purchaseCommandPaymentStatuses";

export default Vue.extend({
  components: { StatisticsPage },
  data() {
    return {
      userManager,
      productsPickerOptions: {
        attrs: {
          label: "Sản phẩm",
          "item-value": "_id",
          "item-text": "sku",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const { items } = await coreApiClient.call("products", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return displayItemsWithSku(items);
          },
        },
      },
      deliveryStatusesPickerOptions: {
        label: "Trạng thái vận chuyển",
        multiple: true,
        clearable: true,
        items: makeSelectOptions(purchaseCommandDeliveryStatuses),
      },
      paymentStatusesPickerOptions: {
        label: "Trạng thái thanh toán",
        multiple: true,
        clearable: true,
        items: makeSelectOptions(purchaseCommandPaymentStatuses),
      },
      marketsPickerOptions: {
        attrs: {
          label: "Thị trường",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const { items } = await coreApiClient.call("markets", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return items;
          },
        },
      },
    };
  },
});
